.primary {
  color: var(--primary-text-color);
}
.secondary {
  color: var(--secondary-text-color);
}

.tertiary {
  color: var(--primary-color);
}

.large {
  font-size: var(--heading-font-size);
  margin: 0px 0px 8px 0px;
}
.normal {
  font-size: var(--default-font-size);
  margin: 0px 0px 8px 0px;
  line-height: 1.6rem;
}

.small {
  font-size: var(--paragraph-font-size);
  margin: 0px 0px 8px 0px;
}

.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semibold {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.justify {
  text-align: justify;
}
.listItem {
  margin-left: 1rem;
}
