.footer {
  position: relative;
  margin: 0 auto;
  padding: 1rem 0rem;
  max-width: var(--max-width);
  .container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    color: var(--primary-text-color);
    gap: 20px;
    align-items: flex-start;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
    .website {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem 0rem;
      gap: 1rem;
      .brand {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 5px;
        text-decoration: none;
        .logo {
          width: auto;
          height: auto;
          display: flex;
        }
        .text {
          width: auto;
          height: auto;
          display: flex;
        }
      }
      .copyright {
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        color: var(--secondary-text-color);
      }
    }
    .links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: row;
      align-items: flex-start;
      flex-direction: column;
      gap: 24px;
      padding: 1rem 0rem;
      @media screen and (min-width: 500px) {
        grid-auto-flow: column;
      }
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }

      .linkGroup {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .title {
          font-size: var(--default-font-size);
          font-weight: 500;
        }
        .link {
          color: var(--secondary-text-color);
          font-weight: 500;
          &:hover {
            text-decoration: underline;
            text-underline-offset: 4px;
          }
        }
      }
    }
  }
}

.footerIconGrid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 20px;

  & a {
    font-size: 24px;
  }
}
