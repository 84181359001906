.button {
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
}

.secondary {
  background-color: var(--bg-secondary-color);
  padding: 0.5rem 2rem;
  color: var(--primary-text-color);

  &:hover {
    background-color: var(--bg-secondary-color-hover);
  }

  &:active {
    background-color: var(--bg-secondary-color-active);
  }
}

.ghost {
  background-color: transparent;
  padding: 0.5rem 1rem;
  color: var(--secondary-text-color);

  &:hover {
    background-color: var(--bg-secondary-color-hover);
  }

  &:active {
    background-color: var(--bg-secondary-color-active);
  }
}
.link {
  color: var(--primary-text-color);
  text-decoration-color: var(--primary-color);
  text-decoration: none;
  text-underline-offset: 2px;
  outline: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--primary-color);

    outline: none;
  }
  &:active {
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
}
.roundedIcon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 0px;
}
